/* Search */
.search {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;

  &.is-visible {
    z-index: 98;
    opacity: 1;

    .search__box {
      transform: translateY(-20px);
    }
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 94;
    top: 0;
    right: 0;
    width: 17px;
    height: 100%;
    background-color: var(--background-color);
  }
}

.search__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 96;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.search__box {
  position: relative;
  z-index: 100;
  max-width: 700px;
  margin: 120px auto 0;
  padding: 60px;
  border-radius: 8px;
  background: var(--background-color);
  transition: all .25s ease;
  will-change: transform;

  @media only screen and (max-width: $desktop) {
    margin: 120px auto 0;
  }

  @media only screen and (max-width: $tablet) {
    padding: 40px;
  }

  @media only screen and (max-width: $mobile) {
    padding: 32px;
  }
}

.search__group {
  position: relative;
  margin-bottom: 32px;

  .search__close {
    position: absolute;
    right: 28px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    font-size: 21px;
    line-height: 1;
    border-radius: 50%;
    color: var(--background-color);
    cursor: pointer;
    will-change: transform;
    transition: all .25s;
    background: var(--heading-font-color);

    &:hover {
      transform: translateY(-50%) rotate(90deg);
      color: var(--white);
      background: var(--brand-color);
    }

    .ion-md-close {
      vertical-align: middle;
    }

    @media only screen and (max-width: $mobile) {
      right: 24px;
    }
  }

  .search__text {
    width: 100%;
    padding: 28px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    border: 2px solid var(--heading-font-color);
    border-radius: 8px;
    color: var(--heading-font-color);
    background-color: var(--background-color);
    outline: 0;
    transition: all .25s;

    &::placeholder {
      font-weight: 700;
      color: var(--heading-font-color);
    }

    &:focus {
      border-color: var(--brand-color);
    }

    &::-ms-clear {
      display: none;
    }
  }

  @media only screen and (max-width: $mobile) {
    margin-bottom: 32px;

    .search__text {
      padding: 24px;
    }
  }
}

.search-results-list {
  max-height: calc(80vh - 260px);
  color: var(--heading-font-color);
  overflow-x: hidden;
  overflow-y: auto;
  white-space: normal;

  .search-results__item {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid var(--background-alt-color);

    &:hover {
      .search-results__title {
        text-decoration-color: var(--heading-font-color);
      }
    }

    @media only screen and (max-width: $mobile) {
      flex-direction: column;
      padding-bottom: 16px;
      margin-bottom: 16px;
    }
  }

  .search-results__image {
    position: relative;
    display: inline-block;
    margin-right: 16px;
    min-width: 80px;
    height: 80px;
    border-radius: 8px;
    overflow: hidden;
    background: var(--background-alt-color);

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
    }

    @media only screen and (max-width: $mobile) {
      margin-right: 0;
      min-width: 100%;
      height: 200px;
    }
  }

  .search-results__content {
    position: relative;
    display: block;
    padding: 10px 0 10px 0;
    transition: inherit;

    &:hover {
      .search-results__title {
        text-decoration-color: var(--heading-font-color);
      }
    }

    @media only screen and (max-width: $mobile) {
      padding: 16px 0 0 0;
    }
  }

  .search-results__date {
    display: block;
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 1;
    color: var(--text-alt-color);
  }

  .search-results__title {
    font-family: $heading-font-family;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.3;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: $global-transition;
  }

  .no-results {
    width: 100%;
    margin: 0;
    color: var(--heading-font-color);
  }
}