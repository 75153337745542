// *****************
// Global Variables
// *****************

// Spaces
$base-spacing-unit: 16px;

// Border radius
$global-radius: 8px;

// Transition
$global-transition: all .25s;


// *****************
// Typography
// *****************
$base-font-size: 18px;
$base-font-style: normal;
$base-font-variant: normal;
$base-font-weight: normal;
$base-font-family: 'Noto Sans Display', Helvetica Neue, Helvetica, Arial, sans-serif;
$base-line-height: 1.7;

// Headings
$heading-font-weight: 700;
$heading-font-family: 'Noto Serif', 'Times New Roman', Georgia, serif;

$font-size-h1:  36px;
$font-size-h2:  28px;
$font-size-h3:  24px;
$font-size-h4:  20px;
$font-size-h5:  18px;
$font-size-h6:  16px;

$heading-line-height: 1.3;
$heading-letter-spacing: normal;