/* Color Scheme */

:root {
  --brand-color: #0056fe;
  --white: #fff;
  --light-gray: #f0f0f0;
  --light-blue: #f3f7ff;
  --blue-gray: #677387;
  --gray: #9e9e9e;
  --dark: #0c101a;
  --dark-blue: #040b1f;
  --rgb-image-color: 255, 255, 255;
  
  --image-gradient: linear-gradient(180deg, rgba(var(--rgb-image-color), 0.8), rgba(var(--rgb-image-color), 1));

  --background-color: var(--white);
  --background-alt-color: var(--light-blue);
  
  --text-color: var(--dark-blue);
  --text-alt-color: var(--blue-gray);
  
  --heading-font-color: var(--dark-blue);

  --link-color: var(--dark-blue);
  --link-color-hover: var(--dark-blue);

  --button-color: var(--white);
  --button-background-color: var(--dark);
  --button-background-hover: var(--brand-color);

  --border-color: var(--light-blue);
  --border-color-alt: var(--light-blue);

  --th-color: var(--light-gray);
  --tr-color: var(--light-gray);

  --syntax-highlighting-background: #f3f3f3;
}

:root[dark] {
  --brand-color: #0073ec;
  --white: #fff;
  --light-gray: #f0f0f0;
  --gray: #bdbdbd;
  --dark: #1f2934;
  --dark-blue: #161d26;
  --rgb-image-color: 22, 29, 38;
  
  --image-gradient: linear-gradient(180deg, rgba(var(--rgb-image-color), 0.8), rgba(var(--rgb-image-color), 1));

  --background-color: var(--dark-blue);
  --background-alt-color: var(--dark);
  
  --text-color: var(--gray);
  --text-alt-color: var(--gray);
  
  --heading-font-color: var(--light-gray);

  --link-color: var(--light-gray);
  --link-color-hover: var(--light-gray);

  --button-color: var(--white);
  --button-background-color: var(--background-alt-color);
  --button-background-hover: var(--brand-color);

  --border-color: #252629;
  --border-color-alt: #080b12;

  --th-color: #18181d;;
  --tr-color: #080b12;

  --syntax-highlighting-background: #080b12;
}