/* Author + Authors */

// Authors Page
.authors {
  margin-bottom: 100px;

  .authors__head {
    margin: 60px 0;
  }

  .authors__title {
    font-size: 52px;
    text-align: center;
  }

  .authors__info {
    margin-bottom: 40px;
    text-align: center;
  }

  .authors__image {
    position: relative;
    transform: translate(0);
    display: block;
    width: 200px;
    height: 200px;
    margin: 0 auto 32px;
    border-radius: 50%;
    overflow: hidden;
    background: var(--background-alt-color);
    transition: .2s;

    &:hover {
      transform: translateY(-4px);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
    }
  }

  .authors__name {
    margin-bottom: 16px;
    line-height: 1;

    .authors__link {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-decoration-color: transparent;

      &:hover {
        text-decoration-color: var(--link-color-hover);
      }
    }
  }

  .authors__posts__count {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    color: var(--heading-font-color);
  }

  .authors__description {
    max-width: 380px;
    margin: 0 auto;
  }

  @media only screen and (max-width: $tablet) {
    .authors__title {
      font-size: 40px;
    }

    .authors__description {
       max-width: 100%;
    }
  }

  @media only screen and (max-width: $mobile) {
    margin-bottom: 60px;

    .authors__head {
      margin: 32px 0 40px;
    }

    .authors__title {
      font-size: 32px;
    }

    .authors__image {
      width: 160px;
      height: 160px;
      margin: 0 auto 24px;
    }

    .authors__name {
      margin-bottom: 8px;
      font-size: 24px;
    }

    .authors__description {
       display: none;
    }
  }
}

// Author Page
.author {
  margin-bottom: 100px;

  .author__content {
    margin: 60px 0 100px;
    text-align: center;

    p {
      max-width: 580px;
      margin: 0 auto 20px;
      font-size: 21px;
    }
  }

  .author__image {
    position: relative;
    transform: translate(0);
    width: 200px;
    height: 200px;
    margin: 0 auto 32px;
    border-radius: 50%;
    overflow: hidden;
    background: var(--background-alt-color);

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
    }
  }

  .author__name {
    margin-bottom: 16px;
    line-height: 1;
  }

  .author__posts__count {
    margin-bottom: 20px;
    font-weight: 600;
    color: var(--heading-font-color);
  }

  .author__info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 24px;

    .author__social__item {
      padding: 8px 20px;
      margin-right: 8px;
      margin-bottom: 8px;
      font-size: 16px;
      border-radius: 30px;
      color: var(--link-color);
      background: var(--background-alt-color);

      &:hover {
        i {
          transform: scale(1.15);
        }
      }

      &:last-child {
        margin-right: 0;
      }

      i {
        margin-right: 4px;
        will-change: transform;
        transition: all .2s;
      }
    }
  }

  @media only screen and (max-width: $mobile) {
    margin-bottom: 60px;

    .author__content {
      margin: 32px 0 64px;
      p {
        font-size: inherit;
      }
    }

    .author__image {
      width: 160px;
      height: 160px;
    }

    .author__name {
      font-size: 32px;
    }

    .author__posts__count {
      font-size: 16px;
    }

    .author__info {
      .author__social__item {
        padding: 8px 16px;
        margin-right: 6px;
        margin-bottom: 6px;
        font-size: 14px;
      }
    }
  }
}
