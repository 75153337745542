/* Subscribe */
.subscribe {
  position: relative;
  padding: 80px 0;
  overflow: hidden;
  background: var(--dark);
}

.subscribe__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--dark);
    opacity: 0.95;
  }
}

.subscribe__inner {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .subscribe__content {
    margin-right: auto;

    .subscribe__title {
      margin-bottom: 20px;
      font-size: 52px;
      line-height: 1;
      color: var(--white);
    }

    .subscribe__description {
      max-width: 380px;
      margin-bottom: 0;
      color: var(--gray);
    }
  }

  .subscribe-form {
    display: inline-flex;
    width: 100%;
    max-width: 620px;
    padding: 24px;
    border-radius: 8px;
    background: var(--background-color);

    .subscribe-email {
      flex: 1;
      height: 60px;
      margin-right: 8px;
      padding: 20px;
      font-size: 16px;
      line-height: 1;
      color: var(--heading-font-color);
      border: 1.5px solid rgba(103, 115, 135, 0.35);
      border-radius: 8px;
      outline: 0;
      background: transparent;
      transition: all .25s ease;

      &::placeholder {
        color: var(--heading-font-color);
      }

      &:focus {
        border-color: var(--brand-color);
      }
    }

    .subscribe-button {
      height: 60px;
    }
  }

  @media only screen and (max-width: $wide) {
    .subscribe__content{
      .subscribe__title {
        font-size: 42px;
      }
    }

    .subscribe-form {
      max-width: 440px;
    }
  }

  @media only screen and (max-width: $desktop) {
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;

    .subscribe__content {
      .subscribe__description {
        max-width: 100%;
        margin-bottom: 32px;
      }
    }

    .subscribe-form {
      max-width: 100%;
    }
  }

  @media only screen and (max-width: $tablet) {
    .subscribe__content {
      .subscribe__title {
        font-size: 26px;
      }
    }

    .subscribe-form {
      padding: 20px;
    }
  }

  @media only screen and (max-width: $mobile) {
    .subscribe__content {
      .subscribe__description {
        font-size: 16px;
      }
    }

    .subscribe-form {
      display: flex;
      flex-direction: column;

      .subscribe-email {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
  }
}
