/* Tags */
.tags__inner {
  margin-bottom: 68px;

  &.is-hidden {
    display: none;
  }

  .tag__info {
    padding: 60px 0;
    text-align: center;

    @media only screen and (max-width: $desktop) {
      padding: 40px 0;
    }

    @media only screen and (max-width: $mobile) {
      padding: 32px 0;
    }
  }
  
  .tag__name {
    margin-bottom: 0;
    font-size: 52px;
    line-height: 1;
    text-transform: capitalize;
  }
  
  .tag__counter {
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 1.6px;
    text-transform: uppercase;
  }

  @media only screen and (max-width: $tablet) {
    .tag__name {
      margin-bottom: 16px;
      font-size: 40px;
    }
  }

  @media only screen and (max-width: $mobile) {
    margin-bottom: 48px;

    .tag__name {
      font-size: 32px;
      margin-bottom: 12px;
    }
  }
}