/* Hero */
.hero {
  padding: 60px 0;
  margin-bottom: 40px;

  @media only screen and (max-width: $desktop) {
    padding: 40px 0;
    margin-bottom: 32px;
  }

  @media only screen and (max-width: $desktop) {
    padding: 32px 0;
  }
}

.hero__inner {
  max-width: 860px;
  margin: 0 auto;
  text-align: center;
}

.hero__title {
  margin-bottom: 20px;
  font-size: 50px;
  color: var(--heading-font-color);

  @media only screen and (max-width: $desktop) {
    font-size: 50px;
  }

  @media only screen and (max-width: $tablet) {
    font-size: 40px;
  }

  @media only screen and (max-width: $mobile) {
    font-size: 32px;
  }
}

.hero__description {
  margin: 0 auto;
  max-width: 620px;
  font-size: 21px;
  color: var(--text-alt-color);

  @media only screen and (max-width: $desktop) {
    font-size: inherit;
  }
}

.hero__image {
  position: absolute;
  top: 0;
  z-index: -1;
  height: 460px;
  width: 100%;
  overflow: hidden;

  .hero__image__inner {
    position: relative;
    width: 100%;
    height: 100%;

    &::after {
      content: "";
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      will-change: transform;
      background: var(--image-gradient);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}